<template>
   
   

    <div>

      <article > 
        <section class="container" id="currently">
          <h3 class="titreAccueil" align="left"> Ce que je fais en ce moment </h3>
          <p id="sepBG"></p>
          <span class="accueil-info"> Actuellement en troisième année de licence informatique à l'université d'Avignon, <br>
          j'ai pu me spécialiser cette année en IL ( Ingénierie du logiciel ).
          <br> Afin de valider ma licence, je réalise en ce moment un stage au sein de l'entreprise Isidoors.
          </span>
        </section>

      </article>

       <article > 
        <section class="container" id="myProjet">
          <h3 class="titreAccueil" align="left"> Projets réalisés </h3>
          <p id="sepV"></p>
          <span class="accueil-info">
           Durant cette année scolaire, j'ai dû réaliser énormément de projets, surtout en groupe.<br>
            En ce qui concerne ces projets, il y en a deux qui ont retenu mon attention : <br>
           <ul class="container container-block" align="center"> 
             <li>Le premier, consistait à faire un jeu de morpion, dans lequel nous utilisions 
                de l'IA ( Intelligence artificielle ).J'ai trouvé cela très intérressant, d'autant
                plus nous en parlions très peu en classe.
             </li>
             <li>Enfin, le deuxième projet était de faire une application 
               android, qui tirait ses données d'une API ( en anglais: "Application programming interface", en français : " Interface de programmation " ) 
               faite par l'université, ce qui me parraissait plus concret.
             </li>
           </ul>
          </span>
        </section>

      </article>

     <article > 
        <section class="container" id="study">
          <h3 class="titreAccueil" align="left"> Où j'étudie ... </h3>
          <p id="sepB"></p>
          <span class="accueil-info">
           Depuis la rentrée 2018, je suis étudiante à l'université d'Avignon. <br>
           Il faut savoir que cette dernière, est composée de deux sites: une au centre-ville, et une autre plus éloignée: à Agroparc.<br>
           Étant en cursus informatique, j'étudie au CERI ( Centre enseignement et de recherche informatique ) à Agroparc.
            <!-- mettre image -->

          </span>
        </section>

      </article>


    </div>

</template>

<script>
export default {
    name:'Accueil',
    data(){
        return{
            para:' Cette page web a été faite grâce à vuejs,'
            +' il y a également l integration de vue-routeur ',
        }
    },
    

}
</script>
