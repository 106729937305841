import Vue from 'vue'
import VueRouter from 'vue-router'
import Accueil from '../components/Accueil.vue'
import NotFound from '../views/NotFound.vue'
Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/FormationsView',
    name: 'FormationsView',
    component: () => import(/* webpackChunkName: "FormationsView" */ '../components/Formations.vue')
  },
  {
    path: '/PersonneView',
    name: 'PersonneView',
    component: () => import(/* webpackChunkName: "PersonneView" */ '../components/Personne.vue')
  },
  {
    path: '/ExperiencesView',
    name: 'ExperiencesView',
    component: () => import(/* webpackChunkName: "ExperiencesView" */ '../components/Experiences.vue')
  },
  {
    path: '/HobbiesView',
    name: 'HobbiesView',
    component: () => import(/* webpackChunkName: "HobbiesView" */ '../components/Hobbies.vue')
  },
  {
    path: '/myCV',
    name: 'myCV',
    component: () => import(/* webpackChunkName: "myCV" */ '../views/AllCV.vue')
  },
  {
    path: '/accueil',
    name: 'accueil',
    component: () => import(/* webpackChunkName: "accueil" */ '../components/Accueil.vue')
  },
  {
    path: '/competences',
    name: 'competences',
    component: () => import(/* webpackChunkName: "accueil" */ '../components/Competences.vue')
  },
  {
    // permet de gerer les erreurs 404
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
 
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
