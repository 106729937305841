<template>
<div>
  <Accueil></Accueil>
</div>
</template>

<script>
import Accueil from "../components/Accueil.vue"
export default {
    components:{
        Accueil,
    }
}
</script>

<style>

</style>